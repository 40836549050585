import React from "react";
import Slider from "./Slider";
import "./Style/home.css";
import { FaHandshake, FaMicroscope } from "react-icons/fa";
import { GiFlatTire } from "react-icons/gi";
import { Ri24HoursFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
function Home() {
  const navigate = useNavigate();
  return (
    <div>
      <Slider />

      <div>
        {/* About Us  */}
        <div className="about-us-container">
          <div className="about-us-content">
            <div className="about-us-image">
              <img
                src="/aboutus01.avif" // Replace with your image URL
                alt="About Us"
                className="about-image"
              />
            </div>
            <div className="about-us-text">
              <h2 className="about-header">About Us</h2>
              <h3 className="about-subheader">
                <span className="underline">Who We Are</span>
              </h3>
              <p className="about-description">
                Aavid FinServ is a comprehensive financial services provider
                dedicated to offering tailored solutions for individuals and
                businesses. With a focus on reliability, innovation, and client
                satisfaction, Aavid FinServ specializes in loan facilitation,
                financial planning, and wealth management. Backed by a team of
                experienced professionals, the company ensures seamless and
                efficient financial solutions designed to meet diverse client
                needs.
              </p>
              <button
                className="read-more-btn"
                onClick={() => navigate("/about-us")}
              >
                Read More
              </button>
            </div>
          </div>
        </div>

        <div>
          {/* Section 1: OUR SPECIALISTS */}
          {/* <div className="specialists-section">
            <div className="container">
              <h2 className="specialists-header">OUR SPECIALISTS</h2>
            </div>
          </div> */}

          {/* Why Choose Us Section */}
          <div className="why-choose-us-section">
            <div className="container">
              <h3 className="why-choose-us-header">Why Choose Us</h3>
              <div className="categories">
                <div className="category">
                  <div className="logo-container">
                    <FaMicroscope className="category-icon" />
                  </div>
                  <h4 className="category-title">High Quality Services</h4>
                  <p>
                    We provide top-notch services to ensure the best results.
                  </p>
                </div>
                <div className="category">
                  <div className="logo-container">
                    <GiFlatTire className="category-icon" />
                  </div>
                  <h4 className="category-title">Fast Working Process</h4>
                  <p>Our processes are optimized for efficiency and speed.</p>
                </div>
                <div className="category">
                  <div className="logo-container">
                    <Ri24HoursFill className="category-icon" />
                  </div>
                  <h4 className="category-title">24/7 Customer Support</h4>
                  <p>
                    We are available around the clock to assist you with any
                    queries.
                  </p>
                </div>
                <div className="category">
                  <div className="logo-container">
                    <FaHandshake className="category-icon" />
                  </div>
                  <h4 className="category-title">We Have Expert Team</h4>
                  <p>
                    Our team is experienced and skilled to deliver the best
                    results.
                  </p>
                </div>
              </div>
              <p className="content">
                In the context of medical diagnostic laboratories, like the
                Karunya Hitech Diagnostic Center mentioned earlier, these labs
                focus on testing samples to diagnose diseases, monitor treatment
                progress, or assess the overall health of individuals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
