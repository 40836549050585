import React, { useEffect } from "react";
import "./Style/home.css";
function AboutUs() {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <div>
      <div>
        {/* Banner Section */}
        <div className="banner">
          <img src="./slider01.jpg" alt="Banner" className="banner-image" />
        </div>

        {/* About Us Section */}
        <div className="about-us-container">
          <div className="about-us-content">
            <div className="about-us-image">
              <img
                src="./aboutus01.avif" // Replace with your image URL
                alt="About Us"
                className="about-image"
              />
            </div>
            <div className="about-us-text">
              <h2 className="about-header">About Us</h2>
              <h3 className="about-subheader">
                <span className="underline">Who We Are</span>
              </h3>
              <p className="about-description">
              Aavid FinServ is a comprehensive financial services provider
                dedicated to offering tailored solutions for individuals and
                businesses. With a focus on reliability, innovation, and client
                satisfaction, Aavid FinServ specializes in loan facilitation,
                financial planning, and wealth management. Backed by a team of
                experienced professionals, the company ensures seamless and
                efficient financial solutions designed to meet diverse client
                needs.
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
