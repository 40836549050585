import React from "react";
import "./Style/home.css";
import "./Style/service.css";
import { FaHeartbeat } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function Services() {
  const navigate = useNavigate();
  return (
    <div>
      {/* Banner Section */}
      <div className="banner">
        <img src="./services.jpg" alt="Banner" className="banner-image" />
      </div>

      <div>
        <div>
          {/* Service Area Section */}
          <h3 className="section-header">Service Area</h3>
          {/* Our Services Section */}
          <div className="services-section">
            <div className="service-item">
              <img
                src="./personallone.jpg"
                alt="personallone"
                className="service-image"
              />
              <h4>Personal Loan</h4>
              <p>
                Quick and flexible loans to meet personal expenses such as
                medical emergencies, travel, weddings, or debt consolidation,
                with minimal documentation and easy repayment options.
              </p>
              {/* <button
                className="book-now"
                onClick={() => navigate("/contact-us")}
                navigate
              >
                Book Now
              </button> */}
            </div>

            <div className="service-item">
              <img
                src="./homeloan.jpg"
                alt="homeloan"
                className="service-image"
              />
              <h4>Home Loan</h4>
              <p>
                Affordable and convenient financing options to help you
                purchase, build, or renovate your dream home, offering
                competitive interest rates and flexible tenures.
              </p>
              {/* <button className="book-now">Book Now</button> */}
            </div>

            <div className="service-item">
              <img
                src="./businessloan.jpg"
                alt="businessloan"
                className="service-image"
              />
              <h4>Business Loan</h4>
              <p>
                Customized loans designed to support business growth, including
                working capital, expansion plans, or equipment purchase,
                ensuring smooth business operations.
              </p>
              {/* <button className="book-now">Book Now</button> */}
            </div>
          </div>
          <br />
          <div className="services-section">
            <div className="service-item">
              <img
                src="./carlone.jpg"
                alt="carlone"
                className="service-image"
              />
              <h4>Car Loan</h4>
              <p>
                Hassle-free loans with attractive rates to finance your new or
                pre-owned vehicle, making your dream car a reality.
              </p>
              {/* <button
                className="book-now"
                onClick={() => navigate("/contact-us")}
                navigate
              >
                Book Now
              </button> */}
            </div>

            <div className="service-item">
              <img
                src="./educationlone.jpg"
                alt="educationlone"
                className="service-image"
              />
              <h4>Education Loan</h4>
              <p>
                Financial assistance to support higher education, covering
                tuition fees, accommodation, and other academic expenses for
                studying in India or abroad.
              </p>
              {/* <button className="book-now">Book Now</button> */}
            </div>

            <div className="service-item">
              <img
                src="./loneagains property.jpg"
                alt="loneagains property"
                className="service-image"
              />
              <h4>Loan Against Property</h4>
              <p>
                Secure substantial funds by leveraging your residential or
                commercial property, with flexible repayment options for
                personal or business needs.
              </p>
              {/* <button className="book-now">Book Now</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
