import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import "./Style/home.css";
function ContactUs() {
  return (
    <div>
      <div className="contact-us-container">
        {/* Banner Section */}
        <div className="banner">
          <img
            src="./slider03.jpg" // Replace with your banner image URL
            alt="Contact Us Banner"
            className="banner-image"
          />
        </div>
        {/* Contact Details Section */}
        <div className="contact-details">
          <h2 className="details-title">Contact Details</h2>
          <div className="address-details">
            <div>
              <ul className="details-list">
                <li>
                  <FaPhoneAlt className="icon" /> +91 9108199819
                </li>
                <li>
                  <FaEnvelope className="icon" /> aavidfinservb@gmail.com
                </li>
                <li>
                  <FaMapMarkerAlt className="icon" /> 34, Wind Tunnel Rd,
                  Murugeshpalya, Muniyappa Layout, Bengaluru, Karnataka 560017
                </li>
              </ul>
            </div>
            {/* <div>
              <ul className="details-list">
                <li>
                  <FaPhoneAlt className="icon" /> +91 72041 90353
                </li>
                <li>
                  <FaEnvelope className="icon" /> karunyadiagnostic@gmail.com
                </li>
                <li>
                  <FaMapMarkerAlt className="icon" />
                  Settihalli, Karnataka 562160, India, Jalahalli, Bengaluru,
                  Karnataka, India, and Kereguddadahalli, Chikkabanavara,
                  Bengaluru, Karnataka 560090, India
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        {/* Map and Form Section */}
        <div className="contact-content">
          {/* Left Section: Map */}
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2120.10690445375!2d77.65155265606462!3d12.953085565406424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU3JzExLjgiTiA3N8KwMzknMTAuMSJF!5e0!3m2!1sen!2sin!4v1733573386764!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>

          {/* Right Section: Form */}
          <div className="form-container">
            <h2 className="form-title">Send Us a Message</h2>
            <form className="contact-form">
              <input
                type="text"
                placeholder="Your Name"
                className="form-input"
                required
              />
              <input
                type="tel"
                placeholder="Your Phone"
                className="form-input"
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                className="form-input"
                required
              />
              <textarea
                placeholder="Your Message"
                className="form-textarea"
                rows="4"
                required
              ></textarea>
              <button type="submit" className="send-btn">
                Send Enquiry
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
