import { CCarousel, CCarouselItem, CImage } from '@coreui/react';
import React from 'react'
import "./Style/HomeSlider.css";
function Slider() {

    const slides = [
        { id: 1, image: "./slider01.jpg",  },
        { id: 2, image: "./slider02.jpg",  },
        { id: 3, image: "./slider03.jpg",  },
        { id: 4, image: "./slider04.jpg",  },
        // { id: 5, image: "./slider05.jpg", text: "Slide Five Content" },
      ];

  return (
    <div>
    <div className="slider-wrapper">
      {/* Main Carousel */}
      <CCarousel
        controls
        indicators
        transition="crossfade"
    
      >
        {slides.map((slide, index) => (
          <CCarouselItem key={slide.id}>
            <CImage
              class="d-block slider-image"
              src={slide.image}
              alt={`Slide ${slide.id}`}
            />
            {/* <div class="slider-text">{slide.text}</div> */}
          </CCarouselItem>
        ))}
      </CCarousel>

 
    </div>
  </div>
  )
}

export default Slider