import React, { useState } from "react";
import {
  FaBars,
  FaHome,
  FaInfoCircle,
  FaPhone,
  FaProductHunt,
  FaTimes,
} from "react-icons/fa";
import "./Style/header.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const navigate = useNavigate();
  return (
    <div>
      <nav className="navbar-01">
        <div className="navbar-header">
          <img src="./logo.jpeg" alt="Logo" className="logo-image" />
          <h4>Aavid Finservb</h4>
          <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <ul className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
          <li className="navbar-item">
            <a href="/" className="navbar-link">
              <FaHome className="link-icon" />
              Home
            </a>
          </li>
          <li className="navbar-item">
            <a href="/about-us" className="navbar-link">
              <FaInfoCircle className="link-icon" />
              About Us
            </a>
          </li>
          <li className="navbar-item">
            <a href="/lab-services" className="navbar-link">
              <FaProductHunt className="link-icon" />
              Services
            </a>
          </li>
          <li className="navbar-item">
            <a href="/contact-us" className="navbar-link">
              <FaPhone className="link-icon" />
              Contact Us
            </a>
          </li>
        </ul>
        {/* <div>
          <Button variant="danger" onClick={() => navigate("/contact-us")}>
            Make Appointment
          </Button>
        </div> */}
      </nav>
    </div>
  );
}

export default Header;
