import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

function Footer() {
  return (
    <div>
      <MDBFooter
        style={{ backgroundColor: "#0475b9", padding: "5px" }}
        bgColor="#0475b9"
        className="text-center text-lg-start text-white"
      >
        {/* <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="facebook-f" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="twitter" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="google" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="instagram" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="linkedin" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="github" />
            </a>
          </div>
        </section> */}

        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <div className="d-flex align-items-center">
                  <img
                    src="./logo.jpeg"
                    alt="Karunya Hitech Diagnostic Center Logo"
                    style={{
                      height: "50px", // Adjust the height as needed
                      width: "50px", // Adjust the width to match the height
                      borderRadius: "50%", // Circular image
                      objectFit: "cover", // Ensure the image fits the circle
                      marginRight: "10px", // Add some space between the image and text
                    }}
                  />
                  <h6 className="text-uppercase fw-bold mb-0">
                    {" "}
                    {/* mb-0 to remove bottom margin */}
                    <MDBIcon icon="gem" className="me-3" />
                    Aavid Finservb
                  </h6>
                </div>
                <p>
                  Aavid FinServ provides tailored financial solutions,
                  specializing in loans, financial planning, and wealth
                  management. With a customer-focused approach and expert
                  guidance, it helps clients achieve their financial goals
                  efficiently and reliably.
                </p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a href="/about-us" className="text-reset">
                    About us
                  </a>
                </p>
                <p>
                  <a href="/contact-us" className="text-reset">
                    Contact Us
                  </a>
                </p>
                <p>
                  <a href="/lab-services" className="text-reset">
                    Our Servce
                  </a>
                </p>
                {/* <p>
                  <a href="#!" className="text-reset">
                    Laravel
                  </a>
                </p> */}
              </MDBCol>

              {/* <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact Info</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Pricing
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Settings
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Orders
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Help
                  </a>
                </p>
              </MDBCol> */}

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  34, Wind Tunnel Rd, Murugeshpalya, Muniyappa Layout, Bengaluru, Karnataka 560017
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  aavidfinservb@gmail.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> + 91 9108199819
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2024 Copyright:
          <a className="text-reset fw-bold" href="https://teciexsolutions.com/">
            Teciex Solutions
          </a>
        </div>
      </MDBFooter>
    </div>
  );
}

export default Footer;
